import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { SecuredCustomerConsents } from '@resursbank/ya-react-lib';

const stateToProps = state => {
    return {
        env: state.info.env,
    };
};

const CustomerConsent = ({ env, consentTypesChannel }) => {
    const data = useMemo(() => {
        const isProd = env === 'prod';
        return {
            endpointUrl: isProd
                ? 'https://consentmanager-cust-yabank-prod.apps.okd.paas.h.bitbit.net'
                : 'https://consentmanager-cust-yabank-stage.apps.okd.paas.h.bitbit.net',
            keycloakData: {
                realm: 'kunder',
                clientId: 'forside',
                url: isProd
                    ? 'https://keycloak.yabank.c.bitbit.net'
                    : 'https://keycloak-test.yabank.c.bitbit.net',
                consentTypesChannel,
            },
            consentTypesChannel: 'ya',
            cmsHelperUrl: isProd
                ? 'https://cmshelper-cust-yabank-prod.apps.okd.paas.h.bitbit.net'
                : 'https://cmshelper-cust-yabank-stage.apps.okd.paas.h.bitbit.net',
        };
    }, [env]);
    return <SecuredCustomerConsents {...data} />;
};

CustomerConsent.parseProps = atts => {
    return {
        consentTypesChannel: atts.consentTypesChannel,
    };
};

CustomerConsent.propTypes = {
    env: PropTypes.string,
    consentTypesChannel: PropTypes.oneOf(['ya', 'yx']),
};

export default connect(stateToProps)(CustomerConsent);
